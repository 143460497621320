<template>
  <div class="box">
    <div class="loading"></div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  created() {
    let that = this
    setTimeout(function () {
      axios.get(`/apis/dlj/${that.$route.params.id}`).then(({ data }) => {
        if (data.code == 1) {
          data.data.url
            ? (location.href = data.data.url)
            : (location.href = 'https://www.yxuetong.com/')
        } else {
          location.href = 'https://www.yxuetong.com/'
        }
      })
    }, 500)
  },
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.box {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
